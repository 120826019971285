.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-mobile-logo {
  height: 32vmin;
  pointer-events: none;
  min-height: 200px;
}

@media (prefers-reduced-motion: no-preference) {
}

.App-body {
  background-color: #252729;
  min-height: calc(90vh - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header {
  height: 10vh;
  background-color: #252729;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px 30px;
  gap: 10px;
}

.App-header .searchbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  gap: 10px;
}

.App-mobile-header {
  background: linear-gradient(35deg, #46007b, #a1388c, #ff5b6a);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.logo-text {
  font-size: 24px;
  padding-bottom: 10px;
  font-family: CustomFont;
}

@font-face {
  font-family: "CustomFont";
  src: url("../src/font/PirataOne-Regular.ttf");
}

.App-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  background-color: #252729 !important;
  padding: 20px 0 40px 0;
}

.App-link {
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-hover {
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  margin: 10px;
  text-align: center;
  border: none;
  background-size: 300% 100%;

  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:hover {
  background-position: 100% 0;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.btn-hover:focus {
  outline: none;
}

.btn-hover.nocolor {
  background-image: linear-gradient(
    to right,
    #f8f8f80a,
    #dddddd27,
    #949494,
    #a1a1a100
  );
  box-shadow: 0px 0px 6px 0px rgba(202, 202, 202, 0.342);
}

.btn-hover.color {
  background-image: linear-gradient(
    to right,
    #f325aa,
    #2b9bff,
    #3079dd,
    #d151f1
  );
  box-shadow: 0px 0px 6px 0px rgb(255 105 238 / 75%);
}

.btn-hover.round {
  width: 100px;
  height: 32px;
  font-size: 0.5em;
  font-weight: 400;
  border-radius: 50px;
}

.btn-hover.normal {
  width: 160px;
  height: 40px;
  font-size: 0.5em;
  font-weight: 400;
  border-radius: 10px;
}

.MuiMenu-paper {
  background: linear-gradient(106deg, #ff616d, #cf62e3);
}

.MuiPaper-root
  .MuiMenu-paper
  .MuiPopover-paper
  .MuiPaper-elevation8
  .MuiPaper-rounded {
  top: 100px;
}

.MuiMenuItem-root {
  padding: 0 16px !important;
  color: white;
}

.flag-select {
  padding: 0 !important;
  width: 80px;
}

.buttons {
  font-size: 24px;
  align-self: flex-end;
}
